import { init, register, getLocaleFromNavigator } from 'svelte-i18n';
import { userLocale } from './stores/localeStore';

register('en', () => import('../locales/en.json'));
register('de', () => import('../locales/de.json'));

let initialLocale = 'en';

if (typeof window !== 'undefined') {
	initialLocale = userLocale.get();
}

init({
	fallbackLocale: 'en',
	initialLocale: initialLocale,
});