<script lang="ts">
	import { _ } from 'svelte-i18n';
	import { locales } from 'svelte-i18n';
	import { userLocale } from '$lib/stores/localeStore';
	import { goto } from '$app/navigation';

	function changeLanguage(lang: string) {
		userLocale.set(lang);
		// refresh
		goto('/')
	}
</script>

<div class="language-selector">
	<select id="language-select" on:change={(e) => changeLanguage(e.target.value)}>
		{#each $locales as lang}
			<option value={lang} selected={$userLocale === lang}>
				{$_(`languageSelector.${lang}`)}
			</option>
		{/each}
	</select>
</div>

<style>
    .language-selector {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
    select {
        padding: 0.25rem;
        border-radius: 4px;
        border: 1px solid var(--color-border);
    }
</style>