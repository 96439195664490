<script lang="ts">
	import { onMount } from 'svelte';
	import { formData } from '$lib/stores/formData';
	import { SvelteToast } from '@zerodevx/svelte-toast';
	import LoadingScreen from '$lib/components/atoms/LoadingScreen.svelte';
	import '$lib/styles/_variables.scss';
	import '$lib/styles/global.scss';
	import '$lib/styles/nomo-theme.scss';
	import {
		isPersonalDataSubmitted,
		isAddressDataSubmitted,
		isLoading,
		useMockupData,
		selectedTheme,
		isPersonalVerification,
		isAddressVerification
	} from '$lib/stores/generalStore';
	import { page } from '$app/stores';
	import { goto } from '$app/navigation';
	import { checkServerAvailability } from '$lib/utils/api';
	import { browser } from '$app/environment';
	import { runsAsWebOn } from 'nomo-webon-kit';
	import '../lib/i18n';
	import { waitLocale } from 'svelte-i18n';
	import LanguageSelector from '$lib/components/molecules/LanguageSelector.svelte';


	if (browser && !runsAsWebOn()) {
		// Do not display anything in this case
		goto('/server-unavailable');
	}

	let error = '';
	let toastOptions = {
		duration: 15000
	};

	let currentStepName = '';

	$: {
		currentStepName = $page.url.pathname;
		$isAddressVerification = currentStepName.includes('address');
		$isPersonalVerification = currentStepName.includes('personal');
	}

	onMount(async () => {
		await waitLocale();
		$isLoading = true;
		const isServerAvailable = await checkServerAvailability();
		if (!isServerAvailable) {
			goto('/server-unavailable');
		} else {
			if (!useMockupData) {
				// Your existing logic for non-mockup data
			} else {
				console.log('using Mockup Data: ', useMockupData);
			}
		}
		$isLoading = false;
	});

	$: {
		browser &&
		localStorage.setItem('formData', JSON.stringify($formData));
	}
</script>

<div class="w-full h-full" data-theme={$selectedTheme}>
	{#if error}
		<p>{error}</p>
		<button on:click={() => location.reload()}>Reload</button>
	{:else if !$isLoading}
		<div class="toast-wrapper">
			<SvelteToast options={toastOptions} />
		</div>
		<div class="language-selector-wrapper">
			<LanguageSelector />
		</div>
		<slot />
	{:else}
		<LoadingScreen />
	{/if}
</div>

<style lang="scss">
  :global(#not_in_nomo_dialog) {
    margin: 0;
  }
  .toast-wrapper {
    position: absolute;
    display: contents;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    letter-spacing: 0.05px;
    font-size: 0.9rem;
    --toastContainerBottom: 2.8vh;
    --toastContainerRight: auto;
    --toastContainerTop: auto;
    --toastContainerLeft: calc(6vw);
    --toastWidth: 88vw;
    --toastBorderRadius: 5px;
    --toastBackground: linear-gradient(45deg, #dbbc77 25%, #eece89 100%);
    --toastBarBackground: white;
    --toastMsgPadding: 0 0.6rem;
    --toastBarHeight: 3px;
    --toastContainerZIndex: 999999;
  }

  .language-selector-wrapper {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 1000;
  }
</style>